import React from 'react';
import { TranslationWithHTML } from '@/core/ContainerWithHTML.atom';

interface TreeMapHelpProps {
  type: string;
}

export const TreeMapHelp: React.FunctionComponent<TreeMapHelpProps> = ({ type }) => {
  const conditionIcon = "<i class='fc fc-capsule-set pr3'></i>";
  const chartIcon = "<i class='fa-sharp fa-regular fa-chart-area pr3'></i>";
  const dataIcon = "<i class='fa-sharp fa-regular fa-database pr3'></i>";
  const toolsIcon = "<i class='fa-sharp fa-regular fa-wrench pr3'></i>";

  const renderAddCondition = (
    <>
      <p>
        <TranslationWithHTML
          translationKey="TREEMAP.USES_CAPSULES"
          translationParams={{ CAPSULE_SET_ICON: conditionIcon }}
        />
      </p>
      <p>
        <strong>
          <TranslationWithHTML
            translationKey="TREEMAP.ADD_CONDITION"
            translationParams={{
              CHART_ICON: chartIcon,
              DATA_ICON: dataIcon,
              TOOLS_ICON: toolsIcon,
            }}
          />
        </strong>
      </p>
    </>
  );

  const renderMustUseSameAsset = (
    <p>
      <TranslationWithHTML
        translationKey="TREEMAP.MUST_USE_SAME_ASSET"
        translationParams={{
          CAPSULE_SET_ICON: conditionIcon,
          CHART_ICON: chartIcon,
        }}
      />
    </p>
  );

  const renderSelectPriorities = (
    <>
      <p>
        <strong>
          <TranslationWithHTML
            translationKey="TREEMAP.SELECT_PRIORITIES"
            translationParams={{ CAPSULE_SET_ICON: conditionIcon }}
          />
        </strong>
      </p>
    </>
  );

  const displayText = () => {
    switch (type) {
      case 'addCondition':
        return renderAddCondition;
      case 'mustUseSameAsset':
        return renderMustUseSameAsset;
      case 'selectPriorities':
        return renderSelectPriorities;
      default:
    }
  };

  return <> {displayText()} </>;
};

export default TreeMapHelp;
